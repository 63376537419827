/** @format */

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core"

import { ProfesoresLayoutsModule } from "./layouts/profesores_layouts.module"
import { ProfesoresLandingModule } from "./landing/profesores_landing.module"
import { ProfesoresUsuariosModule } from "./usuarios/profesores_usuarios.module"
import { ProfesoresEnsayosModule } from "./ensayos/profesores_ensayos.module"
import { ProfesoresEstadisticasModule } from "./estadisticas/profesores_estadisticas.module"
import { ProfesoresInstrumentosModule } from "./instrumentos/profesores_instrumentos.module"
import { ProfesoresGrupoUsuariosModule } from "./grupo_usuarios/profesores_grupo_usuarios.module"
import { ProfesoresPlanEstudiosModule } from "./plan_estudios/profesores_plan_estudios.module"
import { ProfesoresEstablecimientosModule } from "./establecimientos/profesores_establecimientos.module"
import { config } from "../config/config"
import { environment } from "../environments/environment"
import { AppComponent, getProviders, PuntajeCoreModule } from "@puntaje/puntaje/core"

@NgModule({
    imports: [
        ProfesoresLayoutsModule,
        ProfesoresLandingModule,
        ProfesoresUsuariosModule,
        ProfesoresEnsayosModule,
        ProfesoresEstadisticasModule,
        ProfesoresInstrumentosModule,
        ProfesoresGrupoUsuariosModule,
        ProfesoresPlanEstudiosModule,
        ProfesoresEstablecimientosModule,
        PuntajeCoreModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent],
    providers: getProviders(config, environment)
})
export class AppModule {}
