<loading-layout #loadingLayout>
    <div class="row">
        <div class="col-md-6 col-sm-12">
            <form *ngIf="usuarioAvatarEdit" novalidate [formGroup]="formAvatar">
                <cui-section-headline>Foto de perfil</cui-section-headline>
                <div class="avatar-edit-container">
                    <cui-button class="btn_style edit-avatar-btn" type="button" (click)="toggleAvatarEdit($event)">
                        Cambiar imagen
                    </cui-button>
                    <div *ngIf="usuarioAvatarEdit" class="avatar-container">
                        <loading-layout #loadingLayoutAvatar [opaque]="true" [loading]="false">
                            <img
                                class="avatar"
                                alt="avatar"
                                *ngIf="usuarioAvatarEdit.avatar"
                                [src]="usuario.avatar | trustedurl"
                                (load)="onLoadProfileImage()"
                            />
                            <img
                                class="avatar"
                                alt="no avatar"
                                *ngIf="!usuarioAvatarEdit.avatar"
                                src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/shared/default-profile.png"
                                (load)="onLoadProfileImage()"
                            />
                        </loading-layout>
                    </div>
                    <div id="avatarInput" [class.show-file-name]="usuarioAvatarEdit.file">
                        <form-input
                            [form]="formAvatar"
                            [params]="paramsAvatar['file']"
                            [(value)]="usuarioAvatarEdit['file']"
                            [key]="'file'"
                            [xhr_upload]="s3Service.xhr_upload"
                        ></form-input>
                        <div class="avatar-buttons clearfix">
                            <cui-button
                                type="button"
                                class="btn_style pull-right"
                                *ngIf="usuarioAvatarEdit.file"
                                (click)="saveProfileImage()"
                            >
                                Aceptar
                            </cui-button>
                            <cui-button
                                buttonType="cancel"
                                type="button"
                                class="btn_cancel pull-right"
                                *ngIf="usuarioAvatarEdit.file"
                                (click)="cancelNewAvatar()"
                            >
                                Cancelar
                            </cui-button>
                        </div>
                    </div>
                </div>
            </form>
            <form *ngIf="usuarioEdit" novalidate [formGroup]="form">
                <cui-section-headline>Información personal</cui-section-headline>

                <div class="user-info-container">
                    <cui-button
                        class="edit-user-info-btn"
                        [class.btn_style]="!edit_user_info"
                        [class.btn_cancel]="edit_user_info"
                        type="button"
                        (click)="toggleUserInfo($event)"
                    >
                        <span *ngIf="!edit_user_info">Editar</span>
                        <span *ngIf="edit_user_info">Cancelar</span>
                    </cui-button>
                    <div class="user-info" [class.hide]="edit_user_info">
                        <label>{{ params.nombre.label }}:</label>
                        <p>{{ usuario.nombreCompleto() }}</p>
                        <label>{{ params.email.label }}:</label>
                        <p>{{ usuario?.email }}</p>

                        <div *ngIf="enableShowCountryData">
                            <div *ngFor="let idP of idPais">
                                <label>{{ idPaisAliases[idP] }}:</label>
                                <p>
                                    {{
                                        usuario["usuario_" + pais] && usuario["usuario_" + pais][idP.toLowerCase()]
                                            | easyplaceholder: "-"
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <!--Edicion-->
                    <div class="user-edit" [class.show]="edit_user_info">
                        <form-input
                            [form]="form"
                            [params]="params['id']"
                            [(value)]="usuarioEdit['id']"
                            [key]="'id'"
                        ></form-input>
                        <form-input
                            [form]="form"
                            [params]="params['nombre']"
                            [(value)]="usuarioEdit['nombre']"
                            [key]="'nombre'"
                        ></form-input>
                        <form-input
                            [form]="form"
                            [params]="params['apellido_paterno']"
                            [(value)]="usuarioEdit['apellido_paterno']"
                            [key]="'apellido_paterno'"
                        ></form-input>
                        <form-input
                            [form]="form"
                            [params]="params['apellido_materno']"
                            [(value)]="usuarioEdit['apellido_materno']"
                            [key]="'apellido_materno'"
                        ></form-input>
                        <form-input
                            [form]="form"
                            [params]="params['email']"
                            [(value)]="usuarioEdit['email']"
                            [key]="'email'"
                        ></form-input>
                        <div class="clearfix user-edit-buttons">
                            <cui-button (click)="save()" type="button" class="btn_style pull-right">Guardar</cui-button>
                            <cui-button
                                buttonType="cancel"
                                (click)="clear()"
                                type="button"
                                class="btn_cancel pull-right"
                            >
                                Deshacer
                            </cui-button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-md-6 col-sm-12">
            <cui-section-headline>Opciones</cui-section-headline>
            <cui-button *ngIf="!edit_password" class="btn_style" type="button" (click)="showEditPassword()">
                Cambiar contraseña
            </cui-button>
            <div *ngIf="edit_password">
                <div class="edit-pass">
                    <cui-button
                        buttonType="cancel"
                        class="btn_cancel edit-user-info-btn"
                        type="button"
                        (click)="showEditPassword()"
                    >
                        Cancelar
                    </cui-button>
                    <usuario-edit-password></usuario-edit-password>
                </div>
            </div>
            <br />
            <ng-container *ngIf="enableTutor">
                <cui-section-headline>Tutorias</cui-section-headline>
                <tutores [usuario]="usuario"></tutores>
            </ng-container>
        </div>
    </div>
</loading-layout>
