import { EstadisticasComponent, ProgresoComponent } from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"

import { AsignaturasGuard } from "@puntaje/nebulosa/api-services"

export const estadisticasRoutes: Routes = [
    {
        path: "estadisticas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EstadisticasComponent, canActivate: [AuthGuard, AsignaturasGuard] }]
    },
    {
        path: "progreso",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: ProgresoComponent, canActivate: [AuthGuard, AsignaturasGuard] }]
    }
]

export const estadisticasRouting = RouterModule.forChild(estadisticasRoutes)
