import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component } from "@angular/core"
import { MenuItemBoxData } from "@puntaje/shared/layouts"

@Component({
    templateUrl: "home_establecimientos.component.html"
})
export class HomeEstablecimientosComponent {
    menuItems: MenuItemBoxData[] = []

    constructor(protected loggedLayoutService: LoggedLayoutService) {}

    ngOnInit() {
        this.setMenuItems()

        this.loggedLayoutService.setOptions({})
    }

    setMenuItems() {
        this.menuItems = [
            {
                route: "/estadisticas/resumen_uso",
                params: "",
                label: "Resumen de uso de tus establecimientos",
                text: "Monitorea la actividad y el uso general de la plataforma de los docentes y estudiantes que pertenecen a tus establecimientos.",
                linkText: "Ver resumen",
                icon: "estadistica-o"
            },
            {
                route: "/establecimientos/administrar",
                params: { evaluacion_tipo: "ensayo" },
                label: "Administrar establecimientos",
                text: "Encuentra aquí la lista completa de cursos, docentes y estudiantes. Crea y edita cursos, y asocia sus docentes y estudiantes respectivos. Además puedes editar datos y restablecer contraseñas.",
                linkText: "Ir al panel de administración",
                icon: "establecimiento-o"
            },
            {
                route: "/grupo_usuarios",
                params: "",
                label: "Mis cursos",
                text: "¿También eres docente? Encuentra aquí la lista solo con tus cursos asociados. (Para una lista más completa y con más opciones, recomendamos la sección 'Administrar establecimientos'.)",
                linkText: "Ver mis cursos",
                icon: "comunidad-o"
            }
        ]
    }
}
