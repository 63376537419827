<nav class="navbar navbar-default">
    <div class="navbar-collapse collapse">
        <ul class="nav navbar-nav">
            <li>
                <a [routerLink]="['/planes_estudio']" role="button">
                    <ogr-icon name="libro-resultados" align="text-bottom"></ogr-icon>
                    &nbsp;Planes de Estudio
                </a>
            </li>
            <li>
                <a [routerLink]="['/ensayos/generar']" role="button">
                    <ogr-icon name="prueba" align="text-bottom"></ogr-icon>
                    &nbsp;Generar Ensayo
                </a>
            </li>
            <li>
                <a [routerLink]="['/ensayos']" role="button">
                    <ogr-icon name="guia" align="text-bottom"></ogr-icon>
                    &nbsp;Historial Ensayos
                </a>
            </li>
            <li>
                <a [routerLink]="['/evaluacion_instancias/subir']" role="button">
                    <ogr-icon name="hacer-prueba" align="text-bottom"></ogr-icon>
                    &nbsp;Cargar Resultados
                </a>
            </li>
            <li>
                <a [routerLink]="['/estadisticas']" role="button">
                    <ogr-icon name="estadistica-o" align="text-bottom"></ogr-icon>
                    &nbsp;Estadísticas
                </a>
            </li>
            <li>
                <a [routerLink]="['/progreso']" role="button">
                    <ogr-icon name="estadistica-o" align="text-bottom"></ogr-icon>
                    &nbsp;Progreso Estudiantes
                </a>
            </li>
            <li *ngIf="authService.getEstablecimientos().length == 0">
                <a [routerLink]="['/cursos']" role="button">
                    <ogr-icon name="comunidad-o" align="text-bottom"></ogr-icon>
                    &nbsp;Mis Cursos
                </a>
            </li>
            <li *ngIf="authService.getEstablecimientos().length != 0" routerLinkActive="active-menu">
                <a [routerLink]="['/establecimientos/administrar']" role="button">
                    <ogr-icon name="establecimiento-o" align="top"></ogr-icon>
                    &nbsp;Administrar
                </a>
            </li>
        </ul>
    </div>
</nav>
