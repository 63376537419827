<div class="landing-wrap">
    <section class="left-section">
        <div>
            <nav class="navbar-top">
                <div class="navbar-logo">
                    <img
                        src="/assets/img/landing/logo-st.png"
                        alt="{{ config.plataforma.info.companyName }}"
                        title="{{ config.plataforma.info.companyName }}"
                    />
                </div>
                <ul class="top-links">
                    <li><a href="https://www.tupuedes.cl/carreras/" target="_blank">Ver carreras</a></li>
                    <li>
                        <a href="https://www.tupuedes.cl/sedes/" target="_blank">Sedes</a>
                    </li>
                    <li><a href="https://www.tupuedes.cl/becas-beneficios/" target="_blank">Becas y beneficios</a></li>
                </ul>
            </nav>
            <div class="headline">
                <div class="landing-img">
                    <img src="/assets/img/landing/EnsayoOnlinePAES.jpg" />
                </div>
                <p class="subtitle">
                    Comienza a practicar para la nueva
                    <br />
                    Prueba de Selección Universitaria.
                </p>
            </div>
        </div>
        <div class="acreditacion">
            <img
                src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/ust/landing/sellos-acreditaciones-light.png"
            />
        </div>
    </section>
    <section class="right-section">
        <div class="top-btn-container">
            <a href="https://www.tupuedes.cl/formulario-de-cotizacion/" target="_blank" class="arancel-btn"></a>
            <a href="https://www.tupuedes.cl/formulario-de-simulacion/" target="_blank" class="simulador-btn"></a>
        </div>
        <div>
            <login-ust [enableContactUs]="false" [enableRegister]="true" [enablePasswordRecovery]="true"></login-ust>

            <p class="bottom-link">
                <a class="registro" (click)="showRegister()">Regístrate aquí</a>
            </p>
        </div>
        <div class="row">
            <div class="col-md-6">
                <ul class="bottom-links">
                    <li>
                        <a href="https://www.tupuedes.cl/te-llamamos/" target="_blank">
                            <fa class="icon" name="comments-o"></fa>
                            ¿Tienes dudas? Te llamamos
                        </a>
                    </li>
                    <li>
                        <a href="https://www.tupuedes.cl/sedes/" target="_blank">
                            <fa class="icon" name="clock-o"></fa>
                            Horarios de atención
                        </a>
                    </li>
                    <li>
                        <a href="https://www.tupuedes.cl/contacto/" target="_blank">
                            <fa class="icon" name="envelope"></fa>
                            Contáctanos
                        </a>
                    </li>
                </ul>
            </div>
            <div class="col-md-6">
                <ul class="bottom-links">
                    <li>
                        <a href="tel:6004444444" target="_blank">
                            <fa class="icon" name="phone"></fa>
                            600 444 4444
                        </a>
                    </li>
                    <li>
                        <a href="https://www.tupuedes.cl/preguntas-frecuentes/" target="_blank">
                            <fa class="icon" name="question-circle"></fa>
                            Preguntas frecuentes
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</div>
<generic-modal [buttonClicked]="openModal" [id]="'registro'" (closeCrossCallback)="cancelar()" class="modal-registro">
    <modal-titulo>Regístrate aquí</modal-titulo>
    <modal-contenido class="modal-contenido">
        <usuario-registro-ust #registroUst *ngIf="showRegisterForm"></usuario-registro-ust>
    </modal-contenido>
</generic-modal>
