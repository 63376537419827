import { PlanClasesComponent, PlanClaseComponent, ClasePlanClaseComponent } from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"

import { AuthGuard } from "@puntaje/shared/core"
import { AsignaturasGuard } from "@puntaje/nebulosa/api-services"

export const routes: Routes = [
    {
        path: "planes_estudio",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PlanClasesComponent, canActivate: [AuthGuard, AsignaturasGuard] }]
    },
    {
        path: "asignatura/:asignatura_id/planes_estudio",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PlanClasesComponent, canActivate: [AuthGuard, AsignaturasGuard] }]
    },
    {
        path: "plan_estudios/:id/sesiones/:sesion_id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: ClasePlanClaseComponent, canActivate: [AuthGuard, AsignaturasGuard] }]
    },
    {
        path: "plan_estudios/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PlanClaseComponent, canActivate: [AuthGuard, AsignaturasGuard] }]
    }
]

export const routing = RouterModule.forChild(routes)
