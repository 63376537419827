import { PuntajeLandingModule } from "@puntaje/puntaje/core"
import { CUILayoutsModule } from "@puntaje/shared/cui"
import { NgModule, ModuleWithProviders } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule } from "@angular/forms"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { UtilModule } from "@puntaje/shared/core"

import { ProfesoresLayoutsModule } from "../layouts/profesores_layouts.module"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { SharedLayoutsModule } from "@puntaje/platforms/ust"
import { EnsayosModule } from "@puntaje/puntaje/new-modules/ensayos"
import { GrupoUsuariosModule } from "@puntaje/puntaje/new-modules/grupo-usuarios"
import { UsuariosModule } from "@puntaje/puntaje/new-modules/usuarios"
import { StreamingModule } from "@puntaje/puntaje/new-modules/streaming"
import { NotificacionesModule } from "@puntaje/puntaje/new-modules/notificaciones"
import { MaterialesModule } from "@puntaje/puntaje/new-modules/materiales"
import { LandingModule } from "@puntaje/puntaje/new-modules/landing"

import { HomeComponent } from "./home.component"
import { LandingComponent } from "./landing.component"

import { LandingRouting } from "./profesores_landing.routing"

@NgModule({
    imports: [
        GrupoUsuariosModule,
        CommonModule,
        FormsModule,
        LandingRouting,
        ProfesoresLayoutsModule,
        CommonsLayoutsModule,
        LandingModule,
        UsuariosModule,
        MaterialesModule,
        Angular2FontawesomeModule,
        UtilModule,
        SharedLayoutsModule,
        NotificacionesModule,
        EnsayosModule,
        StreamingModule,
        CUILayoutsModule,
        PuntajeLandingModule
    ],
    declarations: [HomeComponent, LandingComponent],
    exports: [HomeComponent, LandingComponent]
})
export class ProfesoresLandingModule {
    static forRoot(): ModuleWithProviders<ProfesoresLandingModule> {
        return {
            ngModule: ProfesoresLandingModule,
            providers: []
        }
    }
}
