import { PuntajePlanClasesModule } from "@puntaje/puntaje/core"
import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule } from "@angular/forms"

import { PlanEstudiosModule } from "@puntaje/puntaje/new-modules/plan-estudios"
import { ProfesoresLayoutsModule } from "../layouts/profesores_layouts.module"

//components

import { routing } from "./profesores_plan_estudios.routing"

@NgModule({
    imports: [CommonModule, FormsModule, ProfesoresLayoutsModule, PlanEstudiosModule, routing, PuntajePlanClasesModule],
    exports: [],
    declarations: [],
    providers: []
})
export class ProfesoresPlanEstudiosModule {}
