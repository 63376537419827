import { PuntajeInstrumentosModule } from "@puntaje/puntaje/core"
import { NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"

import { ProfesoresLayoutsModule } from "../layouts/profesores_layouts.module"
import { InstrumentosModule } from "@puntaje/puntaje/new-modules/instrumentos"

import { routing } from "./profesores_instrumentos.routing"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ProfesoresLayoutsModule,
        InstrumentosModule,
        routing,
        PuntajeInstrumentosModule
    ],
    exports: [],
    declarations: []
})
export class ProfesoresInstrumentosModule {}
