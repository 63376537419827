import { PuntajeEstadisticasModule } from "@puntaje/puntaje/core"
import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { ProfesoresLayoutsModule } from "../layouts/profesores_layouts.module"
import { EnsayosModule } from "@puntaje/puntaje/new-modules/ensayos"
import { EstadisticasModule } from "@puntaje/puntaje/new-modules/estadisticas"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"

import { estadisticasRouting } from "./profesores_estadisticas.routing"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ProfesoresLayoutsModule,
        EstadisticasModule,
        estadisticasRouting,
        EnsayosModule,
        SharedModule,
        PuntajeEstadisticasModule
    ],
    declarations: [],
    exports: [],
    providers: []
})
export class ProfesoresEstadisticasModule {}
