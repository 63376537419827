import { EvaluationLayoutComponent } from "../layouts/evaluation_layout/evaluation_layout.component"
import {
    EnsayoInstanciasComponent,
    EvaluacionComponent,
    EvaluacionesCorregirComponent,
    GenerarEnsayoProfesorComponent,
    RealizarEnsayoComponent,
    ResultadosEnsayoAlumnoComponent,
    ResultadosEnsayoComponent,
    RevisarEnsayosComponent
} from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"

import { AsignaturasGuard } from "@puntaje/nebulosa/api-services"

export const routes: Routes = [
    {
        path: "ensayo_instancias",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EnsayoInstanciasComponent, canActivate: [AuthGuard] }]
    },
    // { path: 'ensayos/generar_ensayo/:asignatura_id', component: GenerarEnsayoProfesorComponent, canActivate: [AuthGuard] },
    // { path: 'ensayos/generar_ensayo2/:asignatura_id', component: GenerarEnsayoProfesorComponent, canActivate: [AuthGuard] },
    {
        path: "ensayos",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: RevisarEnsayosComponent, canActivate: [AuthGuard, AsignaturasGuard] }]
    },
    // { path: 'ensayos/:id/realizar', component: RealizarEnsayoComponent, canActivate: [AuthGuard] },
    {
        path: "ensayo_instancias/:id",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: ResultadosEnsayoComponent, canActivate: [AuthGuard] }]
    },

    // { path: 'evaluaciones/generar_ensayo/:asignatura_id', component: GenerarEnsayoProfesorComponent, canActivate: [AuthGuard] },
    // { path: 'evaluaciones/generar_ensayo2/:asignatura_id', component: GenerarEnsayoProfesorComponent, canActivate: [AuthGuard] },
    // { path: 'evaluaciones/:id/realizar', component: RealizarEnsayoComponent, canActivate: [AuthGuard] },
    {
        path: "evaluacion_instancias/subir",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EvaluacionesCorregirComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "evaluacion_instancias/:id",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: ResultadosEnsayoComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "ensayos/generar",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: GenerarEnsayoProfesorComponent, canActivate: [AuthGuard, AsignaturasGuard] }]
    },
    {
        path: "ensayos/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EvaluacionComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "ensayo_instancias_estudiante/:id",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: ResultadosEnsayoAlumnoComponent, canActivate: [AuthGuard] }]
    }
]

export const routing = RouterModule.forChild(routes)
