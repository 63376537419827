<ll-titulo>Bienvenid@</ll-titulo>

<div class="row">
    <div class="col-md-4 col-sm-6">
        <usuario-showcasebox></usuario-showcasebox>
        <br />
    </div>
    <div class="col-md-8 col-sm-6">
        <grupo-usuarios-profesor-showcase [rutaGrupoUsuarios]="'/cursos'"></grupo-usuarios-profesor-showcase>
    </div>
</div>