import { SharedLayoutComponent } from "@puntaje/platforms/ust"
import { SimpleSharedLayoutComponent } from "@puntaje/platforms/ust"
import {
    UsuariosEditComponent,
    UsuarioPerfilComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    CompletarRegistroComponent,
    IncompleteRegisterComponent,
    RegisterConfirmationComponent,
    RegisterComponent
} from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"

import { RegisterIncompletoPuntajeComponent, UsuariosUssEditComponent } from "@puntaje/puntaje/new-modules/usuarios"

export const routes: Routes = [
    // { path: 'usuarios/register', component: RegisterComponent, data: { component: RegisterComponent } },
    // { path: 'usuarios/registro', component: RegisterComponent, data: { component: RegisterComponent } },
    { path: "usuarios/confirmar_email/:token", component: RegisterConfirmationComponent },
    {
        path: "usuarios/edit",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: UsuariosEditComponent,
                canActivate: [AuthGuard],
                data: { component: UsuariosUssEditComponent, enableFacebookLink: false }
            }
        ]
    },
    {
        path: "usuarios/editar",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: UsuariosEditComponent,
                canActivate: [AuthGuard],
                data: { component: UsuariosUssEditComponent, enableFacebookLink: false }
            }
        ]
    },
    {
        path: "usuarios/:id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: UsuarioPerfilComponent,
                canActivate: [AuthGuard],
                data: { tab: "", enableFavoritos: false }
            }
        ]
    },
    {
        path: "usuarios/passwords/reset/:reset_password_token",
        component: SimpleSharedLayoutComponent,
        children: [{ path: "", component: ResetPasswordComponent }]
    },
    {
        path: "usuarios/passwords/forgot",
        component: SimpleSharedLayoutComponent,
        children: [{ path: "", component: ForgotPasswordComponent }]
    },
    {
        path: "usuarios/:id/completar_registro",
        component: SharedLayoutComponent,
        children: [{ path: "", component: CompletarRegistroComponent }]
    },
    {
        path: "pending_info",
        component: SharedLayoutComponent,
        children: [
            {
                path: "",
                component: IncompleteRegisterComponent,
                data: { component: RegisterIncompletoPuntajeComponent }
            }
        ]
    }
]

export const routing = RouterModule.forChild(routes)
