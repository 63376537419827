import { PuntajeEnsayosModule } from "@puntaje/puntaje/core"
import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { RouterModule } from "@angular/router"

import { ApiModule } from "@puntaje/puntaje/api-services"
import { routing } from "./profesores_ensayos.routing"

import { EnsayosModule } from "@puntaje/puntaje/new-modules/ensayos"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { InstrumentosModule } from "@puntaje/puntaje/new-modules/instrumentos"

import { ProfesoresLayoutsModule } from "../layouts/profesores_layouts.module"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"

//import { EvaluacionTiempoComponent } from "./evaluacion_tiempo.component";
//import { EvaluacionMenuPreguntasComponent } from "./evaluacion_menu_preguntas.component";

//import { PreguntaEnsayoComponent } from "./pregunta_ensayo.component";

//import { RespuestaEnsayoComponent } from "./respuesta_ensayo.component";
import { UtilModule, FormModule } from "@puntaje/shared/core"
import { NebuModule } from "@puntaje/nebulosa/api-services"

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        EnsayosModule,
        InstrumentosModule,
        ProfesoresLayoutsModule,
        UtilModule,
        routing,
        Angular2FontawesomeModule,
        FormModule,
        SharedModule,
        NebuModule,
        PuntajeEnsayosModule
    ],
    declarations: [],
    exports: []
})
export class ProfesoresEnsayosModule {}
